import React, { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import Layout from "src/layout"
import { Top2, Section } from "src/components/styled"
import { Form, FormRow, Attachment, Submit, TextInput } from "src/components/form"
import { uppercase } from "src/components/Name"
// import DownloadIcon from "src/svg/download.svg"
import functions from "src/helpers/functions"

const INITIAL_VALUES = {
  name: "",
  company: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
}

const ProductsPage: GatsbyPage = () => {
  const { formatMessage } = useIntl()
  const [values, setValues] = useState(INITIAL_VALUES)
  const [sending, setSending] = useState(false)
  // const [file, setFile] = useState<File>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({
      ...values,
      [event.currentTarget.name]: event.currentTarget.value,
    })
  }

  // const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFile(event.currentTarget.files[0])
  // }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSending(true)
    // const data = new FormData(event.currentTarget)
    await functions.post("contact", values)
    setSending(false)
    alert(formatMessage({ id: "contact.sent" }))
    setValues(INITIAL_VALUES)
  }

  return (
    <Layout title="contact">
      <Top2>
        <FormattedMessage id="contact.title" tagName="h1" />
        <FormattedMessage id="contact.text" tagName="p" values={uppercase} />
      </Top2>
      <Section>
        <Form onSubmit={handleSubmit}>
          <FormRow>
            <TextInput name="name" required value={values.name} onChange={handleChange} />
            <TextInput name="company" value={values.company} onChange={handleChange} />
          </FormRow>
          <FormRow>
            <TextInput name="email" required type="email" value={values.email} onChange={handleChange} />
            <TextInput name="phone" type="tel" value={values.phone} onChange={handleChange} />
          </FormRow>
          <FormRow>
            <TextInput name="subject" required value={values.subject} onChange={handleChange} />
          </FormRow>
          <FormRow>
            <TextInput name="message" rows={6} required value={values.message} onChange={handleChange} />
          </FormRow>
          <FormRow>
            <Attachment>
              {/* <DownloadIcon />
            {file ? file.name : <FormattedMessage id="contact.attachment" />}
            <input type="file" name="file" onChange={handleFile} /> */}
            </Attachment>
            <Submit type="submit" disabled={sending}>
              <FormattedMessage id={sending ? "input.sending" : "input.send"} />
            </Submit>
          </FormRow>
        </Form>
      </Section>
    </Layout>
  )
}

export default ProductsPage
